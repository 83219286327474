









































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  ApiBroadcastPatientSelect,
  ApiMedicineLists,
  ApiBroadcastPatientUpdate,
  ApiMedicineSave,
  ApiMedicineDel,
  ApiMedicineSaveAdj,
  ApiAdjSelect,
} from "@/apis";
import { breadcrumb } from "@/utils/breadcrumb";
import ConsultationIndex from "@/views/Supervise/Consultation/index.vue";
import { component } from "vue/types/umd";
import moment from "moment";

@Component({
  components: {},
})
export default class LivePatientDetail extends Vue {
  tableData = [] as any;
  medicineAddTable = [] as any;
  replayMedicineList = [] as any;
  addMedicineIndex = "";
  doctorNoteInfo = "";
  showDeleteIcon = true;
  medicineAddLoading = false;
  showAddIcon = true;
  deleteColumnIndex = null;
  addColumnIndex = null;
  liveInfo = {} as any;
  medicationData = {} as any;
  doctorMedicationData = {} as any;
  addMedicineInfo = {} as any;
  doctorMedicineForm = {
    broadcast_patient_id: 0,
    patient_id: "",
    medicine_order: 0,
    medications: [
      {
        medicine_time: "",
        medicine_name: "",
        dosage: "",
      },
    ],
  } as any;
  medicationsDetail = [
    {
      medicine_time: "",
      medicine_name: "",
      dosage: "",
    },
  ] as any;
  broadcastPatient = {} as any;
  dosageList = ["1/8", "1/4", "1/2", "3/4", "1", "1.25", "1.5", "2", "4"];
  cheifForm: any = {
    id: "",
    patient_health_note: "",
    appeal: "",
    medication_note: "",
  };
  dialogVisible = false;
  dialogAdd = false;
  dialogAddMedicine = false;
  medicineList = [] as any;
  currentTime = "";
  currentDosage = "";
  get HeaderCellStyle(): object {
    return { background: "#F5F7FA", color: "#303133" };
  }
  mounted() {
    this.getLiveInfo();
    this.getMedicineList();
  }
  handleClose() {
    this.dialogVisible = false;
    this.dialogAdd = false;
    Object.keys(this.cheifForm).forEach((key) => {
      this.cheifForm[key] = "";
    });
  }
  handleCloseMedicine() {
    this.dialogAddMedicine = false;
    this.addMedicineInfo = {
      medicine_name: "",
    };
    this.medicineAddTable = [];
    this.medicationsDetail = [
      {
        medicine_time: "",
        medicine_name: "",
        dosage: "",
      },
    ];
  }
  dosageChange(item: any, index: number) {
    // this.medicineAddTable.splice(index, 1, {
    //   medicine_time: item.medicine_time,
    //   dosage: item.dosage,
    // });
    this.currentTime = item.medicine_time;
    this.currentDosage = item.dosage;
  }
  isOperStatus = (status: any) => {
    switch (status) {
      case "1":
      case "是":
        return "是";
      case "0":
      case "否":
        return "否";
      default:
        return "-";
    }
  };
  cancleChief() {
    this.dialogVisible = false;
    this.dialogAdd = false;
  }
  addChief() {
    if (!this.cheifForm.id) {
      this.cheifForm.id = this.$route.query.id;
    }
    ApiBroadcastPatientUpdate(this.cheifForm).then((res) => {
      if (res.success) {
        this.$message({
          message: "修改成功",
          type: "success",
        });
      } else {
        this.$message({
          message: res.message,
          type: "error",
        });
      }
      this.dialogVisible = false;
      this.getLiveInfo();
    });
  }
  editChief() {
    this.cheifForm = {
      id: this.$route.query.id,
      patient_health_note: this.broadcastPatient.patient_health_note || "",
      appeal: this.broadcastPatient.appeal || "",
      medication_note: this.broadcastPatient.medication_note || "",
    };
    this.dialogVisible = true;
  }
  replayMedicine() {
    this.dialogAdd = true;
    this.medicineDoctorReply();
  }
  medicineDoctorReply() {
    ApiAdjSelect({ id: this.broadcastPatient.id }).then((res) => {
      this.replayMedicineList = res.data.medications;
      this.doctorNoteInfo = res.data.question_reply_note;
    });
  }
  addMedicineDoctor() {
    this.$confirm("保存后会无法修改，并且会同步到患者端，确认保存吗？", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      this.medicineAddLoading = true;
      ApiMedicineSaveAdj({
        broadcast_patient_id: this.broadcastPatient.id,
        patient_id: this.broadcastPatient.patient_id,
        question_reply_note: this.doctorNoteInfo || "",
      }).then((res) => {
        if (res.success) {
          this.$message({
            message: "新增成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
        this.dialogAdd = false;
        this.getLiveInfo();
        this.medicineAddLoading = true;
      });
    });
  }
  timeDosageAdd() {
    if (!this.currentTime || !this.currentDosage) {
      this.$message({
        message: "请填写完整",
        type: "error",
      });
      return;
    }
    this.medicineAddTable.push({
      medicine_time: this.currentTime,
      dosage: this.currentDosage,
    });
    this.currentTime = "";
    this.currentDosage = "";
    this.medicationsDetail.splice(0, 1, {
      medicine_time: "",
      medicine_name: "",
      dosage: "",
    });
  }
  addMedicineSubmit() {
    const temp = [] as any;
    this.medicineAddTable.forEach((item: any, index: number) => {
      if (!item.medicine_time || !item.dosage) {
        this.$message({
          message: "请填写完整",
          type: "error",
        });
        return false;
      } else {
        temp.push({
          medicine_time: item.medicine_time,
          medicine_name: this.addMedicineInfo.medicine_name,
          dosage: item.dosage,
        });
      }
    });
    const params = {
      broadcast_patient_id: this.broadcastPatient.id,
      patient_id: this.broadcastPatient.patient_id,
      medicine_order: Number(this.addMedicineIndex),
      medications: temp,
    };
    ApiMedicineSave(params).then((res) => {
      if (res.success) {
        this.$message({
          message: "新增成功",
          type: "success",
        });
        this.medicineDoctorReply();
        this.dialogAddMedicine = false;
        this.addMedicineInfo = {
          medicine_name: "",
        };
        this.medicineAddTable = [];
        this.medicationsDetail = [
          {
            medicine_time: "",
            medicine_name: "",
            dosage: "",
          },
        ];
      }
    });
  }
  delMedicineDoctor(idx: number) {
    ApiMedicineDel({
      broadcast_patient_id: this.broadcastPatient.id,
      medicine_order: idx - 1,
    }).then((res) => {
      if (res.success) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
      } else {
        this.$message({
          message: res.message,
          type: "error",
        });
      }
      // this.dialogAdd = false;
      this.medicineDoctorReply();
    });
  }
  getMedicineList() {
    ApiMedicineLists({}).then((res) => {
      this.medicineList = res.data;
    });
  }
  showIcons(index: any) {
    this.showDeleteIcon = true;
    this.showAddIcon = true;
    this.deleteColumnIndex = index;
    this.addColumnIndex = index;
  }
  hideIcons() {
    this.showDeleteIcon = true;
    this.showAddIcon = true;
    this.deleteColumnIndex = null;
    this.addColumnIndex = null;
  }
  addColumn(index: any) {
    // 处理新增列的逻辑
    console.log("新增列:", index);
    this.addMedicineIndex = index;
    this.dialogAddMedicine = true;
  }
  delMedicine(row: any, index: any) {
    this.medicineAddTable.splice(index, 1);
  }
  getLiveInfo() {
    const params = {
      id: Number(this.$route.query.id),
      broadcast_question_id: Number(this.$route.query.broadcast_question_id),
    };
    ApiBroadcastPatientSelect(params).then((res) => {
      this.liveInfo = res.data;
      this.broadcastPatient = res.data.broadcast_patient_list[0];
      if (!this.broadcastPatient.medications) return;
      this.medicationData = this.broadcastPatient.medications;
      this.doctorMedicationData = this.broadcastPatient.doctor_medications;
      this.tableData = this.generateTableData();
      console.log(this.tableData);
    });
  }
  dateFormat = function (row: any) {
    if (row === undefined) {
      return "-";
    }

    const moment = require("moment");
    return moment(row).format("YYYY-MM-DD");
  };
  dateFormatTime = function (row: any) {
    if (row === undefined) {
      return "-";
    }

    const moment = require("moment");
    return moment(row).format("YYYY-MM-DD HH:mm:ss");
  };
  diseaseJoin = (arr: any) => {
    if (!arr) return "-";
    try {
      const parsedDisease = JSON.parse(arr);
      if (Array.isArray(parsedDisease)) {
        return JSON.parse(arr).join(",");
      }
    } catch (error) {
      return arr;
    }

    return "-";
  };
  dash(data: any) {
    if (data === null || data === "" || data === undefined) {
      data = "-";
    }
    return data;
  }
  getDosage(row: any, name: any) {
    for (const medicineDto of this.tableData.medication_time_dtos) {
      if (medicineDto.medicine_time === row.medicine_time) {
        return this.tableData.medicine_names
          ? medicineDto.dosages[this.tableData.medicine_names.indexOf(name)]
          : "";
      }
    }
    return "";
  }
  generateTableData() {
    const { medicine_names, medication_time_dtos } = this.medicationData;
    const tableData = [];

    // 创建表头行
    const headerRow = {
      date: "日期", // 你可以自定义日期的文本
      ...Object.fromEntries(medicine_names.map((name: any) => [name, name])),
    };

    tableData.push(headerRow);

    // 创建数据行
    for (const { medicine_time, dosages } of medication_time_dtos) {
      const dataRow = {
        date: medicine_time,
        ...Object.fromEntries(
          medicine_names.map((name: any, index: number) => [
            name,
            dosages[index],
          ])
        ),
      };

      tableData.push(dataRow);
    }

    return tableData;
  }
}
